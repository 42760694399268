import React from "react";
import AboutBackgroundImage from "../Assets/about-banner-image.png";
// import { BsFillPlayCircleFill } from "react-icons/bs";

const About = () => {
  const scrollToContact = (event) => {
    event.preventDefault();
    const targetElement = document.querySelector("#contact");
    if (targetElement) {
      const scrollToPosition =
        targetElement.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  };
  return (
    <div id="about" className="about-section-container">
      <div className="about-section-text-container">
        <p className="primary-subheading">About</p>

        <p className="primary-text">
          At Sekura AI, our mission is to transform traditional security systems
          with intelligent, AI-driven technologies. We strive to enhance global
          safety and protection by providing innovative, efficient, and reliable
          security solutions.{" "}
        </p>
        <p className="primary-text">
          Our data privacy vault helps businesses across fintech, retail,
          travel, and healthcare:
        </p>
        <p className="primary-text">
          <ul>
            <li>Isolate and protect sensitive data</li>
            <li>Meet data residency requirements</li>
            <li>Manage PII governance</li>
            <li>Enable secure data sharing</li>
          </ul>
        </p>
        <p className="primary-subheading">Key Capabilities:</p>
        <p className="primary-text">
          <ul>
            <li>Data residency</li>
            <li>Regulatory compliance</li>
            <li>Access governance</li>
            <li>Advanced encryption</li>
            <li>API-first architecture</li>
          </ul>
        </p>
        <div className="about-buttons-container">
          <button className="secondary-button" onClick={scrollToContact}>
            Learn More
          </button>
          {/* <button className="watch-video-button">
            <BsFillPlayCircleFill /> Watch Video
          </button> */}
        </div>
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
    </div>
  );
};

export default About;
