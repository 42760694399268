import React from "react";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/home-banner-image.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";

const Home = () => {
  const scrollToContact = (event) => {

    event.preventDefault();
    const targetElement = document.querySelector("#contact");
    if (targetElement) {
      const scrollToPosition = targetElement.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth',
      });
    }
  };
  return (
    <div id= "home" className="home-container">
      <Navbar />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
          The AI-first platform for Information Security & GenAI Enablement
          </h1>
          <p className="primary-text">
          Our platform is powered by Cryptography and AI to fundamentally transform how you manage Information Security and enable GenAI for the organization.
          </p>
          <button className="secondary-button" onClick={scrollToContact}>
          Discover Sekura <FiArrowRight />{" "}
          </button>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
