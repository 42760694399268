import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image.png";

const Products = () => {
  return (
    <div id="genai" className="about-section-container">
      <div className="product-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="product-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <h1 className="primary-heading">GenAI Governance</h1>
        <div
          className="three-column-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "2rem",
          }}
        >
          <div className="column">
            <p className="primary-subheading">
              Secure LLM Innovation with Sekura
            </p>
            <p className="primary-text">
              Harness AI advances while protecting sensitive data, maintaining
              compliance, and keeping your engineering team focused on core
              products.
            </p>

            <p className="primary-subheading">Key Privacy Challenges</p>
            <p className="primary-text">
              <ul>
                <li>
                  Data & IP Protection: Control access to sensitive training
                  data and prompts
                </li>
                <li>
                  Third-Party Security: Use external models without exposing
                  private information
                </li>
                <li>
                  Regulatory Compliance: Meet evolving data privacy standards
                  with confidence
                </li>
              </ul>
            </p>
          </div>

          <div className="column">
            <p className="primary-subheading">The Sekura Solution</p>
            <p className="primary-text">
              Deploy advanced LLMs safely to enhance both internal operations
              and customer experiences.
            </p>

            <p className="primary-subheading">Core Features</p>
            <p className="primary-text">
              <ul>
                <li>
                  Automated Data Protection: Instantly detect and redact
                  sensitive information during all LLM operations—from training
                  to inference
                </li>
                <li>
                  Access Control: Set granular permissions for data access with
                  time-based controls
                </li>
                <li>
                  Simplified Compliance: Meet global requirements including
                  GDPR, EU AI Act, and DPDP
                </li>
                <li>
                  Cost Efficiency: Leverage public LLMs securely without
                  building costly internal models
                </li>
              </ul>
            </p>

            <p className="primary-subheading">End-to-End Security</p>
            <p className="primary-text">
              Protect sensitive data across your entire LLM workflow:
            </p>
            <p className="primary-text">
              <ul>
                <li>Training and fine-tuning</li>
                <li>Retrieval-augmented generation (RAG)</li>
                <li>Data re-identification</li>
                <li>Third-party sharing</li>
              </ul>
            </p>
          </div>

          <div className="column"></div>
        </div>
      </div>
    </div>
  );
};

export default Products;
