import React, { useState } from "react";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setError(""); // Clear error when typing
    setSuccessMessage(""); // Clear success message when typing
  };

  const handleSubmit = (event) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email pattern

    if (!emailPattern.test(email)) {
      event.preventDefault();
      setError("Please enter a valid email address.");
      setSuccessMessage(""); // Clear success message if there's an error
    } else {
      // Allow form to submit to action URL
      setSuccessMessage(`Thank You for subscribing: ${email}`);
      setEmail(""); // Clear the input after successful submission
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await fetch(form.action, {
        method: form.method,
        body: formData,
      });

      if (response.ok) {
        handleSubmit(event);
        document.getElementById("successMessage").style.display = "block";
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        throw new Error("Form submission failed");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div id="contact" className="contact-page-wrapper">
      <h1 className="primary-heading">Have a Question In Mind?</h1>
      <h1 className="primary-heading">Want a Demo?</h1>
      <form
        className="contact-form-container"
        id="waitlistForm"
        method="POST"
        title="Join Waitlist"
        action="https://shiny-tooth-96ca.tikka-nagi.workers.dev/"
        onSubmit={handleFormSubmit}
      >
        <input
          name="email"
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        <button type="submit" className="secondary-button">
          Notify Me
        </button>
      </form>
      {error && <div className="error-message">{error}</div>}
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}
      <div id="successMessage" style={{ display: "none" }}>
        Form submitted successfully! Page will reload shortly.
      </div>
    </div>
  );
};

export default Contact;
