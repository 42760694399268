import React from "react";
import Logo from "../Assets/logo.png";
import { SiLinkedin } from "react-icons/si";
import CookieConsent from "react-cookie-consent";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="my-cookie-consent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>
        <div className="footer-icons">
          <a
            href="https://www.linkedin.com/company/sekuraai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiLinkedin />
          </a>
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <span>408-335-4664</span>
          <span>contact@sekura.ai</span>
        </div>
        <div className="footer-section-columns">
          <span>
            <a href="/Terms.html">Terms & Conditions</a>
          </span>
          <span>
            <a href="/Privacy.html">Privacy Policy</a>
          </span>
          <span>
            <a href="/Cookies.html">Cookies</a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
