import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image.png";

const Products = () => {
  return (
    <div id="products" className="about-section-container">
      <div className="product-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="product-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <h1 className="primary-heading">Today's CIO needs Sekura</h1>
        <p className="primary-subheading">Use Cases</p>
        <p className="primary-text">
          <ul>
            <li>Information Security</li>
            <li>Data Classification</li>
            <li>Data Catalog</li>
            <li>GenAI Governance</li>
          </ul>
        </p>
        <p className="primary-subheading">Benefits</p>
        <p className="primary-text">
          <ul>
            <li>Recovery from Ransomware Protection</li>
            <li>GenAI for Business Operations</li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default Products;
