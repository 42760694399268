import "./App.css";
import Home from "./Components/Home";
import About from "./Components/About";
import Whoweare from "./Components/Whoweare";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Products from "./Components/Products";
import GenAI from "./Components/GenAI";

function App() {
  return (
    <div className="App">
      <Home />
      <Products />
      <GenAI />
      <About />
      <Whoweare />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
