import React from "react";
import ProfilePic from "../Assets/Tikka_pic.jpg";
import ProfilePic1 from "../Assets/harinder.jpg";
import ProfilePic2 from "../Assets/c.jpg";
import { SiLinkedin } from "react-icons/si";

const testimonials = [
  {
    name: "Tikka Nagi",
    image: ProfilePic,
    title: "Founder",
    text: "As a seasoned Computer Scientist and Security Expert, I bring deep technical proficiency in cybersecurity, networking, and infrastructure design to the entrepreneurial landscape. With a proven track record of developing and executing long-term strategic plans and automation solutions, I specialize in building robust systems that align technology initiatives with business objectives. My experience in establishing client-facing controls and optimizing security posture enhances overall organizational performance. Passionate about driving innovation and growth, I leverage my technical expertise to lead and inspire teams in creating cutting-edge solutions that propel startups forward.",
    linkedin: "https://www.linkedin.com/in/nagii/",
  },
  {
    name: "Harinder",
    image: ProfilePic1,
    title: "Co-Founder",
    text: "With over 15 years of experience in data management, AI governance, and enterprise data strategy, Harinder leads Sekura AI's vision of securing data at scale. He has driven the development and execution of cutting-edge AI solutions, enabling businesses to protect their data while ensuring compliance with industry standards like GDPR and HIPAA. Harinder's background in product management and his leadership in launching innovative solutions have empowered global enterprises to transform their operations securely and efficiently.",
    linkedin: "https://www.linkedin.com/in/harinder1/",
  },
  {
    name: "Chaitanya",
    image: ProfilePic2,
    title: "Member of Founding Team",
    text: "As a dedicated data science and AI professional with nearly two years of experience, I am committed to leveraging advanced technologies to drive transformative change. My inquisitive nature inspires me to develop AI-driven solutions that enable organizations to optimize data utilization while ensuring security and compliance. I am proud to be part of the Sekura AI team, contributing to innovative projects that enhance data protection and operational efficiency.",
    linkedin: "https://www.linkedin.com/in/chaitanya-ramayanam-5a710a1b4/",
  },
];

const Testimonial = () => {
  return (
    <div id="who-we-are" className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Who we are</p>
      </div>
      <div className="testimonial-section-bottom">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <img src={testimonial.image} alt={testimonial.name} />
            <h2>{testimonial.name}</h2>
            <h3>{testimonial.title}</h3>
            <a
              href={testimonial.linkedin}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SiLinkedin />
            </a>

            <p className="testimonial-txt">{testimonial.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
